import '../../assets/styles/App.scss';
import '../../assets/styles/MyAccount.scss';
import {useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccountForm from "../sections/forms/AccountForm";
import storage from '../../utils/storage';
import { isAuthenticated, user } from '../../utils/userAuth';
import { saml, response_type, client_id, scope, redirect_uri, preferred_duration, minimum_duration, state, queryParams } from "../../utils/library/urlParams";
import { SessionClear } from '../features/SessionClear';

function MyAccount () {
    const [validateQuery, setValidateQuery] = useState(false);
    const navigate = useNavigate();
    const tempQuery = storage.getTempQuery();
    const redirectURI = storage.getTempRedirect();

    useEffect(() => {
        //sessions / cognito sessions must not persist, log users out if user sessions are detected on ALL login pages
        document.title = 'Home | Demand Response Direct Participation';
        localStorage.setItem("title", "Authorization | Demand Response Direct Participation");
        if(isAuthenticated(user)){
            storage.setTempQuery(queryParams);
            SessionClear();
        }
        else {
            storage.clearUser();
            if(!saml) {
                storage.setTempQuery(queryParams);
                navigate('/');
            }
            if(saml === 'null') {
                storage.setTempQuery(queryParams);
                navigate('/');
            }
            if(queryParams.client_id) {
                storage.setTempQuery(queryParams);
            }
            if(saml && tempQuery && validateQuery === false){
                setValidateQuery(true);
                navigate(`/authorize/faces/pages/MyAccountLogin.jsp?samlContext=${saml}&response_type=${tempQuery.response_type}&client_id=${tempQuery.client_id}&scope=${tempQuery.scope}&redirect_uri=${tempQuery.redirect_uri}&preferred_duration=${tempQuery.preferred_duration}&minimum_duration=${tempQuery.minimum_duration}&state=${tempQuery.state}`)
            }
            if(queryParams.redirect_uri) {
                storage.setTempRedirect(queryParams.redirect_uri);
            }
        }
      }, [validateQuery, navigate, queryParams, tempQuery, redirectURI, redirect_uri]);
    return (
        <>
        <section className='hero-container'>
            <div className='account-wrap'>
                <div className='account-form'>
                    <h1>Authorize to receive my SDG&E® Energy Usage Data</h1>
                    <p className='label-text'><span className='label-req'></span>Required fields</p>

                    <div className='account-links'>
                        <div className="link-alternate">
                                <p className='alt-link'>
                                    Verify with My Energy
                                </p>
                        </div> 
                        <Link to={validateQuery ? `/authorize/faces/pages/alternateAuthentication.jsp?samlContext=${saml}&response_type=${response_type}&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&preferred_duration=${preferred_duration}&minimum_duration=${minimum_duration}&state=${tempQuery.state}` :`/authorize/faces/pages/alternateAuthentication.jsp?samlContext=${saml}` } className="link-myaccount">
                            <p className='my-link'>
                                Verify with Account Number
                            </p>
                        </Link> 
                       
                    
                    </div>

                    {/* My Account Form */}
                        <AccountForm />
                    {/* My Account Form */}
                    
                    <div className='account-buttons'>
                    {redirectURI !== null && redirectURI !== '' && redirectURI !== undefined && redirectURI !== 'undefined' ? 
                        <button className='cancel' onClick={() => {
                            storage.clearTempQuery();
                            storage.clearTempRedirect();
                            window.location.href = redirectURI+'?state='+tempQuery.state;
                        }}
                            >Cancel
                        </button> 
                        : 
                    <button className='cancel' onClick={() => {
                        storage.clearTempQuery();
                        storage.clearTempRedirect();
                        window.history.go(-5);
                    }}
                    >Cancel
                        </button>
                    } 
                    </div>
                </div>
               
            </div>
        </section>
        
        </>
    )
  }
  
  export default MyAccount;