import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import "../../../assets/styles/DataSharingForm.scss";
import "../../../assets/styles/_mixins/main.scss"
import storage from "./../../../utils/storage";
import { user } from '../../../utils/userAuth';
import { createLoa } from "../../../store/slices/createLoa";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CreatedLoaRedirect from '../../features/CreatedLoa';
import Load from "../../../components/features/Load"
import DatePicker from "react-datepicker";
import { format, add } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../../assets/images/calendar-icon.png";
import { ExclamationCircleFilled } from '@ant-design/icons';
import MultipleDataSharingActive from "./MultipleDataSharingActive";
import { dateToWords } from "../../../utils/endDateToWords";

const DataSharingForm = (drpInfo) => {
  const btnRef= useRef();
  const dispatch = useDispatch();
  const [passed, setPassed] = useState(false);
  const [drpsRecord, setDrpsRecord] = useState(drpInfo);
  const [newFormValue, setNewformValue] = useState({
  });
  const [range, setRange] = useState("ongoing")
  const [review, checkReview]  = useState(false)
  const [confirmEmail, handleConfirmEmail] = useState('')
  const tempQuery = storage.getTempQuery();
  const [errorMsg, setErrorMsg] = useState([])
  let pref_months = 13; //default
  let min_months = 1; //default
  //OVERRIDE DEFAULT IF preferred_duration or minimum_duration exists in localstorage/query strings
  let pref_date = null;
  let min_date = null;
  //set from localstorage -- pref duration
  if(tempQuery.preferred_duration){
    pref_date = tempQuery.preferred_duration;
  }
  //set from localstorage -- min duration
  if(tempQuery.minimum_duration){
    min_date = tempQuery.minimum_duration;
  }
 
  //IF NO QUERY STRINGS ARE DETECTED, Validate from DRP (query strings have top hierarchy);
  //no query strings, set from DRP - pref duration
  if(!tempQuery.preferred_duration || tempQuery.preferred_duration == 'undefined'){
    if(drpInfo.drpInfo.recommendedMonths?.length > 0){
        pref_date = drpInfo.drpInfo.recommendedMonths;
    }
    else {
        pref_date = 13;
    }
  }
  //no query strings, set from DRP - min duration
  if(!tempQuery.minimum_duration || tempQuery.minimum_duration == 'undefined'){
    if(drpInfo.drpInfo.requiredMonths?.length > 0){
        min_date = drpInfo.drpInfo.requiredMonths;
    }
    else {
        min_date = 1;
    }
  }
  
  if(pref_date != null){
    pref_months = pref_date;
  }
  if(min_date != null) {
    min_months = min_date;
  }

  //EligMsg check
  //console.log('Eligibiligy: ');
  //console.log(user.eligMsg);

  const [originalDate, setOriginalDate] = useState(add(new Date(), {months: pref_date}))
  const [loading, showLoading] = useState(0)
  const [redirect, redirectCreatedLoa] = useState(false)
  const isAltLogin = storage.getIsAlternateLogin();
  //for multiple accounts only 
  const [checkedAll, setCheckedAll] = useState(true);
  let chkBoxAccounts = {};
  if(user.relatedAccounts) {
      user.relatedAccounts.map((a) => {
          chkBoxAccounts[a.contractAccountID] = true;
        })
    }
  const [checked, setChecked] = useState(chkBoxAccounts);
  //end multiple accounts checkbox
  //for multiple accounts + active LOA
  const [agreements, setAgreements] = useState(drpInfo.agreements);
  //store all accounts with LOAs in an array
  let allAcctsWithLoa = [];
  if(drpInfo.isMultipleAccount === true && drpInfo.agreements) {
        for(const agreement in agreements ){
        // list all agreements
        for(const account in agreements[agreement].customerAccounts) {
                allAcctsWithLoa.push(agreements[agreement].customerAccounts[account].customerAccountName);
            }

        }
    }
  let errorMsgContainer = []
  const safeWindowOpener = (untrustedURL) => {
    let newWindow=window.open();
    newWindow.opener=null;
    newWindow.location=untrustedURL;
  }
  const handleChange = (e) => {
    if(e.target.name === 'confirm_email_address') {
        handleConfirmEmail(e.target.value)
    }
    setNewformValue({
      ...newFormValue,
      [e.target.name]: e.target.value,
    });
  };
  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedAccounts = []
    if(user.relatedAccounts.length > 1){
        for(let accounts in checked ){
            if(checked[accounts] === true) {
                if(!allAcctsWithLoa.includes(accounts)){
                    selectedAccounts.push(accounts);
                }
            }
        }
    }
    else {
        selectedAccounts.push(user.relatedAccounts[0].contractAccountID)
    }
    newFormValue.customerAccounts = selectedAccounts;
    validateOnSubmit();
    if (errorMsgContainer.length === 0) {
        showLoading(1)
        dispatch(createLoa(newFormValue))
        .then((response)=> {
            setDrpsRecord(storage.getCreateLoa());
            showLoading(0)
            setTimeout(redirectCreatedLoa(true), 3000);
        })
        .finally((e) => {
            showLoading(0)
        });
    } else {
        setErrorMsg(errorMsgContainer)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    return;
  };

    const validateOnSubmit = () => {
        errorMsgContainer = []
        if(range === 'choose end date') { 
            if(newFormValue.authorizationEffectiveEndDate === null || newFormValue.authorizationEffectiveEndDate === "") {
                setNewformValue({
                    ...newFormValue,
                    authorizationEffectiveEndDate : format(originalDate, 'yyyyMMdd')
                })
            }
        } else {
            setNewformValue({
                ...newFormValue,
                authorizationEffectiveEndDate : ""
            })
        }
        //validation for range and end date

        if (isAltLogin) {
            if(newFormValue.email_address === null || newFormValue.email_address === "") {
                errorMsgContainer.push('Email address is required')
            } //validation for email address
    
            if(newFormValue.email_address !== confirmEmail) {
                errorMsgContainer.push('Email address does not match')
            } //confirm email validation

            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(confirmEmail)) {
                errorMsgContainer.push('Confirm Email is not a valid email')
            } //validate email
    
            if (!pattern.test(newFormValue.email_address)) {
                errorMsgContainer.push('Email is not a valid email')
            } //validate email

            const root = window.location.hostname.trim().toString();
            if (root === "clickthrough.sdge-qa.modfactory.sempra.com" || root === "clickthrough.sdge-dev.modfactory.sempra.com") {
                if (!(confirmEmail.endsWith("@sdge.com") || confirmEmail.endsWith("@sdgecontractor.com") || confirmEmail.endsWith("@seucontractor.com"))) {
                errorMsgContainer.push('Email is not valid for lower environment. Please use an SDGE internal domain instead (@sdge, @sdgecontractor, or @seucontractor).')
                } //validate email
            }
        }

        if(!review) {
            errorMsgContainer.push('Please agree with the terms and agreement')
        } //terms and agreement validation

        //validate account
        if (newFormValue.customerAccounts.length === 0) {
            errorMsgContainer.push('Select at least one Account')
        }
        //validate account
    }

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
    setDrpsRecord(drpInfo.drpInfo);
    if(passed === false && 
        newFormValue.emailAddress !== drpsRecord.emailAddress &&
        newFormValue.thirdPartyId !== drpsRecord.thirdPartyId &&
        newFormValue.thirdPartyAlternateID !== drpsRecord.alternateId &&
        //newFormValue.thirdPartyName !== drpsRecord.company
        newFormValue.thirdPartyName !== drpsRecord.name
        ) {
        setNewformValue({
            ...newFormValue,
            emailAddress: drpsRecord.emailAddress,
            customerFirstName: user.first_name,
            customerLastName: user.last_name,
            customerAccounts: [],
            thirdPartyId: drpsRecord.thirdPartyId,
            thirdPartyAlternateID: drpsRecord.alternateId,
            //thirdPartyName: drpsRecord.company,
            thirdPartyName: drpsRecord.name,
            nameOrg: user.nameOrg,
            addressCombinedStreetNameText: user.addressCombinedStreetNameText,
            addressCityText: user.addressCityText,
            addressPostalCode: user.addressPostalCode,
            addressStateOrProvinceCode: user.addressStateOrProvinceCode,
            bpType: user.bpType,
            bpTypeText: user.bpTypeText,
            email_address: isAltLogin ? null : storage.getUser().email,
            authorizationEffectiveEndDate: "",
            
        });;
        setPassed(true);
    }
}, [dispatch, newFormValue, passed, tempQuery, drpsRecord, drpInfo.drpInfo, checked]);

  return (
    <>
        { loading ? <Load></Load> : 
            <form onSubmit={handleSubmit}>
                <div className="data-info-body">
                    {errorMsg.length > 0
                        ? <div className="error">
                        <div className="error-container">
                            <div className="error-text">
                                <ExclamationCircleFilled style={{ fontSize: '27px' }} theme="outlined" /> &nbsp;&nbsp;
                                <b>We are sorry, one or more of the required fields have not been formatted correctly. Please fix following errors:</b>
                                <ul>
                                    {
                                        errorMsg.map((x, index) => {
                                            return <li>{x}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>:''}
                    <div className="row-data">
                        <div className="row">
                            <div><span className='label-req'></span>Required fields</div>
                        </div>
                        <div className="row">
                            <div className="span5 text-label">
                                Account Information
                            </div>

                            {user.relatedAccounts.length < 2 &&
                            <div className="span7">
                                {user.relatedAccounts[0].contractAccountID.replaceAll(user.relatedAccounts[0].contractAccountID.substring(0, 4), '*****')}&nbsp;({user.relatedAccounts[0].contractAccountName})           
                            </div> 
                            }
                            {user.relatedAccounts.length > 1 &&
                            <div className="span7 dsk-spacing">
                                &nbsp;
                            </div>
                            }
                        </div>
                        {user.relatedAccounts.length > 1 &&
                        <div className="row">
                        <div className="row text-left form-indent-label">
                            <div className="field-left">
                                <span className='label-req'></span>
                                Which accounts do you want to share data?:
                            </div>
                            <div className="field-right" style={allAcctsWithLoa.length && allAcctsWithLoa.length >= user.relatedAccounts.length ? {display: 'none'} : {display: 'initial'}}>
                               <div className="accounts-table" >
                                    <div className="accounts-chk-container">
                                        <input type="checkbox" name="chk-listAll" className="accounts-checkbox" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll} />
                                        <span className={checkedAll === true ? 'accounts-checkmark checked' : 'accounts-checkmark unchecked'}></span>
                                        <span className="accounts-label">All Available Accounts</span>
                                    </div>
                                   
                                    <hr></hr>

                                    {
                                        user.relatedAccounts.map((acc) => {
                                        if(!allAcctsWithLoa.includes(acc.contractAccountID)) {
                                            return <>
                                                <div className="accounts-chk-container">
                                                    <input type="checkbox" name={acc.contractAccountID} className="accounts-checkbox" onChange={() => toggleCheck(acc.contractAccountID)} checked={checked[acc.contractAccountID]} value={acc.contractAccountID} />
                                                    <span className={checked[acc.contractAccountID] === true ? 'accounts-checkmark checked' : 'accounts-checkmark unchecked'}></span>
                                                    <span className="accounts-label">{acc.contractAccountID} ( {acc.contractAccountName} )</span>
                                                </div>   
                                            </>
                                            } 
                                        }) 
                                    }
                               </div>
                            </div>
                        </div>
                        </div> 
                        }
                        {/* Only render <MultipleDataSharingActive if an agreement is found */}
                        {user.relatedAccounts.length > 1 && drpInfo.isMultipleAccount === true && agreements &&
                            <MultipleDataSharingActive agreements={agreements} />
                        }
                        {!(allAcctsWithLoa.length && allAcctsWithLoa.length >= user.relatedAccounts.length) ? <>
                        {(user.eligMsg == true) ? <>
                        <div className="row" style={{ lineHeight: "normal" }}>
                            <div className="span5 text-label" style={{ verticalAlign: "top" }}>
                                Program Participation
                            </div>
                            <div className="span7">
                                Please note: You are already enrolled in one or more SDG&E Demand Response programs that may conflict in your enrollment with a Demand Response Provider. You may proceed with authorizing data sharing below.        
                            </div> 
                        </div>
                        </> : null }
                        <div className="row">
                            <div className="span5 text-label">
                                Duration
                            </div>
                            <div className="span7 dsk-spacing">
                                &nbsp;
                            </div>
                        </div>
                        <div className="row">
                            <div className="row text-left form-indent-label">
                                <div className="field-left">
                                    <span className='label-req'></span>
                                    How long do you want to share data?:
                                </div>
                                <div className="field-right">
                                    <div className="radio">
                                        <label>
                                        
                                            <input type="radio" onChange={() => {setRange("ongoing"); setNewformValue({...newFormValue, authorizationEffectiveEndDate : ""}) }} name="range" value="ongoing" checked={range === 'ongoing' ? true : false} />
                                            Ongoing
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" onChange={() => {setRange("choose end date"); setNewformValue({ ...newFormValue, authorizationEffectiveEndDate: format(originalDate, 'yyyyMMdd') }) }} name="range" value="choose end date"  checked={range !== 'ongoing' ? true : false}/>
                                            Choose End Date
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row text-left form-indent-label">
                                <div className="field-left dsk-spacing">
                                    &nbsp;
                                </div>
                                <div className="field-right">
                                    <div className='data-information'>
                                        <div className='information-icon'>&nbsp;</div>
                                        <div className={range === 'ongoing' ? "data-desc" : "data-desc-end"}>
                                            {range === 'ongoing' &&
                                            `Data sharing will begin once authorization has been successfully submitted`
                                            }
                                            {range !== 'ongoing' &&
                                            //dateToWords(drpInfo.drpInfo.company, min_months, pref_months)
                                            dateToWords(drpInfo.drpInfo.name, min_months, pref_months)
                                            }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {range !== "ongoing" && <div className="full-width">
                            <div className="full-width text-left form-indent-label">
                                <div className="field-left dsk-spacing">
                                    &nbsp;
                                </div>
                                <div className="field-right date-margin">
                                    <div className='data-information'>
                                        <div className="date-container">
                                            <div className="date-label"><span className='label-req'></span> End By:</div>
                                            <div className="field-right date-column-right">
                                                {/* ADD PREF AND MIN DATE HERE
                                                <DatePicker ref={btnRef} selected={(originalDate)} onChange={(date) => { setNewformValue({ ...newFormValue, authorizationEffectiveEndDate: format(date, 'yyyyMMdd') }); setOriginalDate(date)}} /> 
                                                
                                                */}
                                                <DatePicker minDate={add(new Date(), {months: min_months})} ref={btnRef} selected={(originalDate)} onChange={(date) => { setNewformValue({ ...newFormValue, authorizationEffectiveEndDate: format(date, 'yyyyMMdd') }); setOriginalDate(date)}} />
                                                <div className="img-content"><img src={CalendarIcon} alt="calendar icon" onClick={()=>{ btnRef.current.click() }} className="calendar-icon" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* Start - Email Address */}
                        {isAltLogin ? (
                            <>
                                <div className="row">
                                    <div className="span5 text-label">
                                        Email Address
                                    </div>
                                    <div className="span7">
                                        This email address will receive notifications confirming authorization.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row text-left form-indent-label form-indent-input-label">
                                        <div className="field-left">
                                            <span className='label-req'></span>
                                            Email Address
                                        </div>
                                        <div className="field-right">
                                            <input id="email_address" onChange={handleChange} type="text" name="email_address" className="inputEmailBox" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row text-left form-indent-label form-indent-input-label">
                                        <div className="field-left">
                                            <span className='label-req'></span>
                                            Confirm Email Address
                                        </div>
                                        <div className="field-right">
                                            <input id="confirm_email_address" onChange={handleChange} type="text" name="confirm_email_address" className="inputEmailBox" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {/* End - Email Address */}
                        <div className="row">
                            <div className="span5 text-label">
                                Terms of Use
                            </div>
                            <div className="span7 dsk-spacing">
                                &nbsp;
                            </div>
                        </div>
                        <div className="row">
                            <div className="row text-left form-indent-label">
                                <div className="field-left">
                                <span className='label-req'></span>
                                Review and agree:
                                </div>
                                <div className="field-right">
                                    <div className='check-req'>
                                        <div><input type="checkbox" onChange={() => checkReview(!review)} /></div>
                                        <div>By clicking on this box, you're indicating that you've reviewed in full and agree to the <a onClick={() => safeWindowOpener("/terms-of-use") }  className="terms-redirection"><u>Terms of Use.</u></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="span12 border-separator"></div>
                        </div>
                        <div className='row'>
                            <div className="row text-right">
                                <Popup open={redirect} trigger={<></>} modal>
                                    <CreatedLoaRedirect></CreatedLoaRedirect>
                                </Popup>
                                <button className="submit-loa" type="submit">Authorize Data Sharing<span className="caret-container">&nbsp;</span></button>
                            </div>
                        </div>
                        </> : null }
                    </div>
                </div>
            </form>
        }
    </>
  );
};

export default DataSharingForm;
